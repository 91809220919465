import api from 'libs/api';
import _template from 'lodash/template';
import { auth as authUrl } from './endpoint';

export const adminLogin = async (data, config = {}) => {
  const resp = await api().post(authUrl.adminLogin, data, config);
  return resp;
};

export const userLogin = async (data, config = {}) => {
  const resp = await api().post(authUrl.login, data, config);
  return resp;
};

export const userForgotPassword = async (data, config = {}) => {
  const resp = await api().post(authUrl.forgot_password, data, config);
  return resp;
};

export const userResetPassword = async (data, config = {}) => {
  const resp = await api().post(authUrl.reset_password, data, config);
  return resp;
};

export const userRegister = async (data, config = {}) => {
  const resp = await api().post(authUrl.register, data, config);
  return resp;
};

export const userLogout = async (config = {}) => {
  const resp = await api().post(authUrl.logout, config);
  return resp;
};

export const getAccountDetail = async (config = {}) => {
  const resp = await api().get(authUrl.profile, config);
  return resp;
};

export const updateProfileDetail = async (data, config = {}) => {
  const resp = await api().post(authUrl.profile, data, config);
  return resp;
};

export const updateProfilePicture = async (data, config = {}) => {
  const resp = await api().post(authUrl.profilePic, data, config);
  return resp;
};

export const updateProfileFile = async (type, data, config = {}) => {
  const url = _template(authUrl.profileFile)({ type });
  const resp = await api().post(url, data, config);
  return resp;
};

export const updateUserEmail = async (data, config = {}) => {
  const resp = await api().put(authUrl.profileEmail, data, config);
  return resp;
};

export const updateUserPassword = async (data, config = {}) => {
  const resp = await api().put(authUrl.profilePassword, data, config);
  return resp;
};

export const getProfileSurveyAnswers = async (id, config = {}) => {
  const url = _template(authUrl.profileSurveyId)({ id });
  const resp = await api().get(url, config);
  return resp;
};

export const saveProfileSurveyAnswers = async (data, config = {}) => {
  const resp = await api().put(authUrl.profileSurvey, data, config);
  return resp;
};

export const isLockProfile = async (id, config = {}) => {
  const url = _template(authUrl.isLockProfile)({ id });
  const resp = await api().get(url, config);
  return resp;
};